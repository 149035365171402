import React, { useState } from "react";
import "./App.css";
import { Room, Screen } from "the-immersion-quest-room/dist";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";

class HomePage extends React.Component<{}, {}> {
  render() {
    return <div> 
      <h1> Home </h1>  
      <ul>
      <li>
          <a href="/room/room-sorinh"> Check la room a Sorinh</a>
        </li>
        <li>
          <a href="/room/room-istenn"> Check la room a Istenn </a>
        </li>
        <li>
          <a href="/room/room-rob1"> Check la room a Rob1</a>
        </li>                
        <li>
          <a href="/room/room-truelle"> Check la room a Truelle</a>
        </li>                        
      </ul>
    </div>;
  }
}

function RoomPage() {
  const { room } = useParams();
  if (room) {
    return <div>
      <Room room={room}></Room>
    </div>;
  } else {
    return <div>You should specify a room</div>;
  }
}

function ScreenPage() {
  const { screen } = useParams();
  const [userEvent, setUserEvent] = useState(false)
  if (screen) {
    if(!userEvent && !window.location.href.includes("noui=1")){
      return <div onClick={() => setUserEvent(true)}>
        <div className="fixed top-0 left-0 right-0 bottom-0  flex"> 
          <div className="w-full p-4 font-bold ">
          You need to click anywhere in the screen before showing anything ...
            </div>
        </div>
      </div>
    }

    return <div>
      <Screen screen={screen}></Screen>
    </div>;
  } else {
    return <div>You should specify a screen</div>;
  }
}

const routes = [
  { path: "/", component: <HomePage /> },
  { path: "/room/:room", component: <RoomPage /> },
  { path: "/screen/:screen", component: <ScreenPage /> },
];

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Router basename={process.env.PUBLIC_URL}>
          <div
            className="h-full"
            style={{ minHeight: "100vh", paddingBottom: "64px" }}
          >
            <div className="min-h-full">
              <Routes>
                {routes.map((it) => {
                  return (
                    <Route
                      key={it.path}
                      path={it.path}
                      element={it.component}
                    />
                  );
                })}
              </Routes>
            </div>
          </div>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
